<template>
  <div class="wrapper">
    <menu-ver :type="typeMenu" :name="typeMenu" />
    <div class="container m-3">
      <div class="table-header">
        <button
          class="btn button buttonCreate"
          data-bs-toggle="modal"
          data-bs-target="#addModal"
          @click="getActions(0)"
        >
          <i class="fas fa-plus-circle"></i> {{ $t("actions.create") }}
        </button>
      </div>
      <table class="table" v-if="exams && exams.length > 0">
        <thead>
          <tr>
            <th scope="col">{{ $t("maintenance.languages.key") }}</th>
            <th scope="col">{{ $t("maintenance.languages.name") }}</th>
            <th scope="col">{{ $t("maintenance.exams.question") }}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in exams" :key="item.exam_id">
            <td>{{ item.exam_id }}</td>
            <td>{{ item.exam_titulo }}</td>
            <td>{{ item.exam_questions }}</td>
            <td style="min-width: 90pt">
              <button
                class="btn button buttonDelete ms-2"
                @click="getActions(2, item)"
                data-bs-toggle="modal"
                data-bs-target="#deleteModal"
                :data-title="$t(`menu.borrar`)"
              >
                <i class="fas fa-trash"></i>
              </button>
              <button
                class="btn button buttonDelete ms-2"
                @click="seeQuestions(item.exam_id)"
                :data-title="$t(`menu.questions`)"
              >
                <i class="fas fa-list"></i>
              </button>
              <button
                class="btn button buttonEdit"
                @click="getActions(1, item)"
                data-bs-toggle="modal"
                data-bs-target="#editModal"
                :data-title="$t(`menu.editar`)"
              >
                <i class="fas fa-edit"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-else>{{ $t("errors.3") }}</p>
    </div>
  </div>

  <!-- Modal add -->
  <div
    class="modal fade"
    id="addModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("maintenance.exams.addTitle", { name: `${examId}` }) }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cleanForms()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3">
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.languages.name`) + " *"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                id="FormName"
                v-model.trim="exam_titulo"
                :placeholder="$t(`maintenance.languages.name`) + '*'"
                maxlength="50"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`mUser.courses.description`)
              }}</label>
              <textarea
                v-model.trim="exam_descripcion"
                class="form-control"
                rows="7"
                style="text-transform: uppercase"
                maxlength="500"
              />
            </div>
            <span
              v-if="formError"
              class="text-danger"
              style="float: right; font-size: 12px"
              >{{ $t("errors.fieldOb") }}</span
            >
            <span v-else style="float: right; font-size: 12px">{{
              $t("errors.comment")
            }}</span>
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="btnSend"
            type="submit"
            class="btn-light btnCrud"
            @click="addExam"
          >
            {{ $t("actions.send") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal delete -->
  <div
    class="modal fade"
    id="deleteModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("maintenance.exams.deleteTitle", { name: `${examId}` }) }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          {{ $t("maintenance.exams.deleteMessage") }}
        </div>
        <div class="modal-footer" style="justify-content: center">
          <button
            id="btnDelete"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            data-bs-target="#responseModal"
            @click="deleteExam"
          >
            {{ $t("maintenance.actions.confirm") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal edit -->
  <div
    class="modal fade"
    id="editModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("maintenance.exams.editTitle", { name: `${examId}` }) }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cleanForms()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3">
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.languages.name`) + " *"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                id="exampleDropdownFormName"
                v-model.trim="exam_titulo"
                :placeholder="$t(`maintenance.languages.name`) + '*'"
                maxlength="50"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`mUser.courses.description`)
              }}</label>
              <textarea
                v-model.trim="exam_descripcion"
                class="form-control"
                rows="7"
                style="text-transform: uppercase"
                maxlength="500"
              />
            </div>
            <span
              v-if="formError"
              class="text-danger"
              style="float: right; font-size: 12px"
              >{{ $t("errors.fieldOb") }}</span
            >
            <span v-else style="float: right; font-size: 12px">{{
              $t("errors.comment")
            }}</span>
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="btnSend"
            type="submit"
            class="btn-light btnCrud"
            @click="editExam"
          >
            {{ $t("actions.send") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal response -->
  <div
    class="modal fade"
    id="responseModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("errors.title") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3" style="text-align: center">
            <div v-if="loading" class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div v-else>
              <div class="modal-body" v-if="result === '0'">
                {{ $t("errors.0") }}
              </div>
              <div class="modal-body" v-else-if="result === '1'">
                {{ $t("errors.1") }}
              </div>
              <div class="modal-body" v-else-if="result === '2'">
                {{ $t("errors.2") }}
              </div>
              <div class="modal-body" v-else-if="result === '3'">
                {{ $t("errors.3") }}
              </div>
              <div class="modal-body" v-else-if="action === 2">
                {{ $t("errors.6") }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" v-if="!loading">
          <button
            v-if="result === '0' || action === 2"
            type="button"
            class="btn button btn-return-result"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("actions.close") }}
          </button>
          <button
            v-if="action === 0 && result !== '0'"
            class="btn button btn-return-result"
            data-bs-target="#addModal"
            data-bs-toggle="modal"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.return") }}
          </button>
          <button
            v-if="action === 1 && result !== '0'"
            class="btn button btn-return-result"
            data-bs-target="#editModal"
            data-bs-toggle="modal"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.return") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuVer from "../../../components/MenuVer";
import axios from "axios";
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    MenuVer,
  },
  data() {
    return {
      exams: [
      ],
      exam: {},
      exam_titulo: "",
      exam_id: "",
      exam_questions: "",
      exam_descripcion: "",
      examId: "",
      result: "",
      action: 0,
      id: "",
      formError: false,
      loading: false,
      typeMenu: ""
    };
  },
  created() {
    this.getExams();
    this.changeBreadCrumb(18);
    this.bigDrop();
    if (window.location.href.indexOf("adminsu") > 0) {
      this.typeMenu = "superuser";
    } else {
      this.typeMenu = "company";
    }
  },
  computed: {
    ...mapState(["servidor"]),
  },
  methods: {
    ...mapMutations(["changeBreadCrumb", "bigDrop"]),
    async getExams() {
      try {
      } catch (error) {
        console.error(error);
      }
      const res = await axios.post(
        `${this.servidor}api/op.asp?tp=exam&ac=listado`
      );
      if (res.data) {
        this.exams = res.data.data;
      }
    },
    async addExam() {
      this.result = "";
      if (this.validateForm()) {
        try {
          this.loading = true;
          const exam = {
            // idio_id: this.idio_id.toUpperCase(),
            exam_titulo: this.exam_titulo.toUpperCase(),
            exam_descripcion: this.exam_descripcion.toUpperCase(),
          };
          // console.log("POST", exam);
          const res = await axios.post(
            `${this.servidor}api/op.asp?tp=exam&ac=grabar`,
            exam
          );
          // console.log("res", res);
          this.result = res.data.RESULT;
          $("#addModal").modal("hide");
          $("#responseModal").modal("show");
        } catch (error) {
          console.error(error);
          this.result = "1";
          $("#addModal").modal("hide");
          $("#responseModal").modal("show");
        }
        this.loading = false;
      }
    },
    async editExam() {
      this.result = "0";
      if (this.validateForm()) {
        try {
          this.loading = true;
          const exam = {
            exam_id: this.exam_id,
            exam_titulo: this.exam_titulo.toUpperCase(),
            exam_descripcion: this.exam_descripcion.toUpperCase(),
          };
          // console.log("PUT", exam);
          const res = await axios.post(
            `${this.servidor}api/op.asp?tp=exam&ac=actualizar`,
            exam
          );
          // console.log("res", res);
          this.result = res.data.RESULT;
          $("#editModal").modal("hide");
          $("#responseModal").modal("show");
        } catch (error) {
          this.result = "1";
          console.error(error);
          $("#editModal").modal("hide");
          $("#responseModal").modal("show");
        }
        this.loading = false;
      }
    },
    async deleteExam() {
      try {
        const borrado = {
          exam_id: this.id,
        };
        this.loading = true;
        const res = await axios.post(
          `${this.servidor}api/op.asp?tp=exam&ac=borrar`,
          borrado
        );
        this.result = res.data.RESULT;
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    getId(event) {
      this.examId = event[0];
    },
    validateForm() {
      // if (this.idio_id === "") {
      //   this.formError = true;
      //   return false;
      // }
      if (this.exam_titulo === "") {
        this.formError = true;
        return false;
      }
      return true;
    },
    cleanForms() {
      this.exam_id = "";
      this.exam_titulo = "";
      this.exam_descripcion = "";
      this.loading = false;
      this.formError = false;
      this.getExams();
    },
    getActions(act, item) {
      this.action = act;
      if (act === 0) {
        return;
      }
      if (act === 1 && item) {
        this.exam = item;
        this.exam_id = item.exam_id;
        this.exam_titulo = item.exam_titulo;
        this.exam_descripcion = item.exam_descripcion;
        return;
      }
      if (item.exam_id) {
        this.id = item.exam_id;
      }
    },
    seeQuestions(id) {
      // console.log(window.location.href.indexOf("adminsu"));
      if (window.location.href.indexOf("adminsu") > 0) {
        this.$router.push({
          path: `/adminsu/mquestions/${id}`,
        });
      } else {
        this.$router.push({
          path: `/admin/mquestions/${id}`,
        });
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

.btn {
  float: right;
}

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title]:after {
  content: attr(data-title);
  background-color: #333;
  color: #fff;
  font-size: 14px;
  position: absolute;
  padding: 10px 20px;
  bottom: 2em;
  left: -5em;
  font-family: "Roboto", sans-serif;
  white-space: nowrap;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
  border-radius: 6px;
}

[data-title] {
  position: relative;
}
</style>